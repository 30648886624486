import { Button, Card, Divider, Form, Input, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { changeLoader } from "../../Redux/reducers/loader";
import { toast } from "react-toastify";
import { addProviderCategories } from "../../Axios/services/ProviderCategories";

const AddCategories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    providerCategoryDisplayName: "",
    providerCategorySearchKey: "",
  });
  //Error
  const [formDataError, setFormDataError] = useState({
    providerCategoryDisplayNameError: "",
    providerCategorySearchKeyError: "",
  });

  //handle error
  const handleError = () => {
    let error = false;
    if (!formData?.providerCategoryDisplayName) {
      setFormDataError((prev) => ({
        ...prev,
        providerCategoryDisplayNameError:
          "Please provide a provider category name",
      }));
      error = true;
    }
    if (
      formData?.providerCategoryDisplayName &&
      formData?.providerCategoryDisplayName.length > 20
    ) {
      setFormDataError((prev) => ({
        ...prev,
        providerCategoryDisplayNameError: "Maximum 20 characters allowed",
      }));
      error = true;
    }
    if (!formData?.providerCategorySearchKey) {
      setFormDataError((prev) => ({
        ...prev,
        providerCategorySearchKeyError:
          "Please provide a search key for this provider category",
      }));
      error = true;
    }
    if (
      formData?.providerCategorySearchKey &&
      formData?.providerCategorySearchKey.length > 50
    ) {
      setFormDataError((prev) => ({
        ...prev,
        providerCategorySearchKeyError: "Maximum 50 characters allowed",
      }));
      error = true;
    }
    return error;
  };

  //hanndle send notification
  const handleSubmit = async () => {
    if (!handleError()) {
      dispatch(changeLoader(true));
      try {
        const response = await addProviderCategories({
          display_name: formData?.providerCategoryDisplayName.trim(),
          search_query: formData?.providerCategorySearchKey.trim(),
        });
        toast[response.ack ? "success" : "error"](
          response.message || "something went wrong!",
          {
            limit: 1,
          }
        );
        dispatch(changeLoader(false));
        response?.ack && navigate("/provider-categories");
      } catch (err) {
        console.log(err);
        dispatch(changeLoader(false));
      }
    }
  };

  return (
    <Card>
      <Typography className="text-xl font-semibold" align={"left"}>
        <LeftOutlined className="mr-2" onClick={() => navigate(-1)} />
        Add Category
      </Typography>
      <Divider />
      <div className="min-h-[calc(100vh_-_305px)]">
        <Form
          name="login"
          layout="vertical"
          style={{
            maxWidth: 500,
            width: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          className=""
          form={form}
        >
          <Form.Item
            label={
              <>
                Display Name
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="name"
          >
            <Input
              size="large"
              value={formData?.clientName}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  providerCategoryDisplayName: e.target.value.trimStart(),
                });
                setFormDataError((prev) => ({
                  ...prev,
                  providerCategoryDisplayNameError: "",
                }));
              }}
            />
            <span className="text-red-500">
              {formDataError?.providerCategoryDisplayNameError}
            </span>
          </Form.Item>
          <Form.Item
            label={
              <>
                Search Query
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="search-Query*"
            help="Only alphabets and commas are allowed"
          >
            <Input
              size="large"
              value={formData?.clientName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z,\s]*$/.test(value)) {
                  setFormData({
                    ...formData,
                    providerCategorySearchKey: value.trimStart(),
                  });
                  setFormDataError((prev) => ({
                    ...prev,
                    providerCategorySearchKeyError: "",
                  }));
                }
              }}
            />
            <span className="text-red-500">
              {formDataError?.providerCategorySearchKeyError}
            </span>
          </Form.Item>
        </Form>
      </div>
      <Divider />
      <div className="flex justify-end gap-3">
        <Button onClick={() => navigate(-1)}>Cancel</Button>
        <Button
          type="primary"
          disabled={
            formData?.providerCategoryDisplayName === "" ||
            formData?.providerCategorySearchKey === ""
          }
          onClick={handleSubmit}
        >
          Add
        </Button>
      </div>
    </Card>
  );
};

export default AddCategories;
