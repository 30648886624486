import React, { useEffect, useState } from "react";
import {
  Tooltip,
  Button,
  Pagination,
  Card,
  Typography,
  Input,
  Select,
} from "antd";
import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import SortingArrow from "../../Common/SortingArrow";
import { useDispatch } from "react-redux";
import { geNotificationList } from "../../Axios/services/Notification";
import { changeLoader } from "../../Redux/reducers/loader";
import { toast } from "react-toastify";
import DateHelper from "../../Helpers/lib/DateHelper";
import { capitlizeFirstLetter } from "../../Helpers/lib/StringHelper";

const { Option } = Select;
const { Search } = Input;

const NotificationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alNnotificationList, setalNnotificationList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [itemTotal, setItemTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedNotificationValue, setSelectedNotificationValue] =
    useState("");

  const [sorting, setSorting] = useState({
    order: "DESC",
    sort_by: "id",
  });

  // Handle search input
  const handleSearch = (value, e) => {
    let searchText;
    if (typeof value === "object") {
      searchText = value.target.value.trimLeft();
    } else {
      searchText = value.trimLeft();
    }
    setSearchValue(searchText);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handle notification type change
  const handleNotificationTypeChange = (value) => {
    setSelectedNotificationValue(value);
    setPage(1);
  };

  // Get notification list based on search, type, and pagination
  const notificationList = async () => {
    dispatch(changeLoader(true));
    try {
      const params = {
        type:
          selectedNotificationValue !== "All Clients"
            ? selectedNotificationValue
            : "",
        search: searchValue,
        page: searchValue.length > 2 ? 1 : page,
        limit: limit,
        // ...sorting,
      };
      const response = await geNotificationList(params);
      if (response.ack) {
        setalNnotificationList(response.data.announcementData);
        setItemTotal(response.data.totalData);
        dispatch(changeLoader(false));
      } else {
        toast.error(response.errors.email, {
          limit: 1,
          toastId: "forgotPasswordError",
        });
        dispatch(changeLoader(false));
      }
    } catch (error) {
      console.error("Error fetching notification list:", error);
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    notificationList();
  }, [page, limit, selectedNotificationValue]);

  useEffect(() => {
    if (searchValue.length > 2 || searchValue === "") {
      notificationList();
    }
  }, [searchValue]);

  const selectItemNotification = [
    { id: "", options: "Filter" },
    { id: "all", options: "All Clients" },
    { id: "custom", options: "Custom" },
  ];

  const handleReset = () => {
    setSearchValue("");
    setSelectedNotificationValue("");
    setPage(1);
  };

  const truncateText = (text, maxLength = 25) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const handleSort = (order, sortby) => {
    let obj = {
      order: order,
      sort_by: sortby,
    };
    setSorting(obj);
  };

  const handleSizeChange = (current, size) => {
    setLimit(size);
    setPage(1);
  };

  return (
    <Card>
      <div className="flex justify-between relative mb-4">
        <Typography
          className="text-lg font-semibold mb-0 whitespace-nowrap"
          align={"left"}
        >
          Manage Notification
        </Typography>
        <div className="flex justify-end gap-2">
          <div className="text-[14px] font-semibold flex items-center whitespace-nowrap">
            Total Notifications - {itemTotal}
          </div>
          <Search
            allowClear
            placeholder="Search by message"
            style={{ width: 190 }}
            className="mx-1.5 cursor-pointer"
            onSearch={handleSearch}
            value={searchValue}
            onChange={handleSearch}
          />
          <Select
            value={selectedNotificationValue}
            onChange={handleNotificationTypeChange}
            style={{ width: 110 }}
          >
            {selectItemNotification.map((selectNotification) => (
              <Option key={selectNotification.id} value={selectNotification.id}>
                {selectNotification.options}
              </Option>
            ))}
          </Select>
          <Button
            onClick={handleReset}
            disabled={!searchValue && selectedNotificationValue === ""}
          >
            Reset
          </Button>

          <Button
            type="primary"
            onClick={() => navigate("/send-notification")}
            icon={<PlusOutlined />}
          >
            Send Notification
          </Button>
        </div>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Message Title
                {/* <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "request_id");
                  }}
                /> */}
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Message Text
                {/* <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "request_id");
                  }}
                /> */}
              </th>
              <th scope="col" className="px-3 py-3">
                User Type
                {/* <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "request_id");
                  }}
                /> */}
              </th>
              <th scope="col" className="px-3 py-3">
                Send On
                {/* <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "request_id");
                  }}
                /> */}
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-center whitespace-nowrap"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {alNnotificationList?.length > 0 ? (
              alNnotificationList?.map((notification, index) => (
                <tr key={index} className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top capitalize cursor-pointer"
                    onClick={() =>
                      navigate(`/view-notification/${notification.id}`)
                    }
                  >
                    {truncateText(notification.title)}
                  </th>
                  <td className="px-3 py-4 vertical-top">
                    {truncateText(capitlizeFirstLetter(notification.message))}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    {notification.user_type === "all"
                      ? "All Client"
                      : "Custom"}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    {DateHelper.format(notification?.createdAt, "m/d/Y h:i A")}
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    <Tooltip title="View">
                      <EyeOutlined
                        onClick={() =>
                          navigate(`/view-notification/${notification.id}`)
                        }
                        className="mx-1.5 cursor-pointer"
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center pt-5">
                  No Notification Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {alNnotificationList?.length > 0 && itemTotal > 10 && (
        <div className="text-right mt-4">
          <Pagination
            current={page}
            pageSize={limit}
            total={itemTotal}
            onChange={handlePageChange}
            onShowSizeChange={handleSizeChange}
            showSizeChanger={true}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </div>
      )}
    </Card>
  );
};

export default NotificationPage;
