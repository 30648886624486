export const storageKeys = {
  ADMIN_TOKEN: "accessToken",
  USER_DATA: "userData",
  ADMIN_PERMISSIONS: "adminPermissions",
};

//https://staging.myclear.health/api/v2"  staging url
//https://staging.myclear.health socket url

//https://myclear.health/api/v2"  production url
//https://myclear.health socket url

const serverType = "devlpment";
export const baseURL_socket =
  serverType == "devlopment"
    ? "https://myclear.clearhealthsolutions.dev"
    : "https://myclear.clearhealthsolutions.dev";
export const baseUrl =
  serverType == "devlopment"
    ? "https://myclear.clearhealthsolutions.dev/api/v2"
    : "https://myclear.clearhealthsolutions.dev/api/v2";
