import {
  Button,
  Card,
  Col,
  Avatar,
  Divider,
  List,
  Row,
  Typography,
  Input,
  Form,
  Image,
} from "antd";
import {
  SendOutlined,
  LeftOutlined,
  UserOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkPermission, strFormat } from "../../Helpers/Functions";
import CloseChat from "../../Modal/CloseChat";
import {
  getAllChatRooms,
  getchatRoom,
  getChatRoomMessage,
  getUnreadCount,
  markchatMessageAsRead,
  sendMessage,
} from "../../Axios/services/InsuranceAgentChat";
import EmptyComponent from "../Empty";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
import addDeleteGetLocalStorage from "../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import DateHelper from "../../Helpers/lib/DateHelper";
import { getSocket, initializeSocket } from "../../Socket/socket";
import { changeAgentReadchatMessage } from "../../Redux/reducers/readchatAgentMessage";
import { changeTrigger } from "../../Redux/reducers/trigger";
import { toast } from "react-toastify";
import fileAzurUpload from "../../Axios/UploadFile";
import {
  fileImageallowedTypes,
  validateFiles,
} from "../../Helpers/lib/ValidationHelper";

const InsuranceAgentView = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const [isViewChatLogModalOpen, setIsViewChatLogModalOpen] = useState(false);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [message, setMessage] = useState("");
  const [allChatRooms, setAllChatRooms] = useState([]);
  const [showStaticPage, setShowStaticPage] = useState(false);
  const trigger = useSelector((state) => state?.trigger?.value);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [type, setType] = useState("load");
  const [search, setSearch] = useState("");
  const chatContainerRef = useRef(null);
  const [sorting, setSorting] = useState({
    order: "DESC",
    sort_by: "updatedAt",
  });

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  const role = jwtDecode(token)?.role;
  const user_id = token ? jwtDecode(token)?.user_id : 0;
  let [chatRoomResponces, setChatRoomResponces] = useState({
    chatRoomData: {},
    chatMessagesData: [],
  });
  const dispatch = useDispatch();
  const handleViewChatLogOk = () => {
    setIsViewChatLogModalOpen(false);
  };
  const handleViewChatLogCancel = () => {
    setIsViewChatLogModalOpen(false);
  };

  const handlegetgetAllChatRooms = async () => {
    try {
      let parms = { ...sorting };
      if (search) {
        parms["search"] = search;
      }
      let res = await getAllChatRooms(parms);
      if (res.ack) {
        setAllChatRooms(res?.data?.rows);
        if (type == "load") {
          setChatRoomId(res?.data?.rows[0]?.id);
        }
      }
    } catch (err) {
      console.log(err);
    }
    if (type == "load" || role == "sale_agent") {
      dispatch(changeLoader(false));
    }
    role == "sale_agent" && setShowStaticPage(true);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      let data = {
        chat_room_id: chatRoomId,
        message: message,
        receiver_id: chatRoomResponces?.chatRoomData?.client_id,
        documents: uploadedImages,
      };
      let responce = await sendMessage(data);
      if (responce.ack) {
        setMessage("");
        setUploadedImages([]);
        setTimeout(() => {
          scrollToBottom();
          setIsSubmitting(false);
        }, 400);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlegetchatRoom = async (id) => {
    if (trigger !== "countValueChanged") {
      return await getchatRoom(id);
    }
  };

  const handlegetChatRoomMessage = async (id) => {
    if (trigger !== "countValueChanged") {
      return await getChatRoomMessage(id);
    }
  };

  const handlemarkchatMessageAsRead = async (id) => {
    return await markchatMessageAsRead(id);
  };

  const handleGetChatRoom = async (id) => {
    type == "load" && dispatch(changeLoader(true));
    try {
      let [chatroomResponce, chatmessageResponce, markchatMessageResponce] =
        await Promise.all([
          handlegetchatRoom(id),
          handlegetChatRoomMessage(id),
          handlemarkchatMessageAsRead(id),
        ]);
      if (
        chatroomResponce?.ack &&
        chatmessageResponce?.ack &&
        markchatMessageResponce?.ack
      ) {
        setChatRoomResponces({
          chatRoomData: chatroomResponce?.data,
          chatMessagesData: chatmessageResponce?.data?.rows,
        });
        dispatch(changeAgentReadchatMessage(true));
      }
    } catch (err) {
      console.log(err);
    }
    type == "load" && dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (allChatRooms?.length > 0 && type == "load") {
      if (trigger !== "countValueChanged") {
        const firstChatRoom = allChatRooms[0];
        handleGetChatRoom(firstChatRoom?.id);
      }
    }
  }, [allChatRooms, type]);

  useEffect(() => {
    let socket = getSocket();
    const chatMessages = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket?.on(`agent-new-chat-message-${chatRoomId}`, (data) => {
        if (data && data?.chat_message?.room_id === chatRoomId) {
          socket?.emit("agent-mark-as-read", chatRoomId);
          if (chatRoomId) {
            dispatch(changeAgentReadchatMessage(true));
          }
        }
        if (data?.chat_message?.room_id === chatRoomId) {
          const newMessage = {
            ...data?.chat_message,
            agent_chat_documents: data?.chat_message?.chatDocuments?.map(doc => ({
              document: doc.document
            })) || []
          };
          setChatRoomResponces((prev) => ({
            ...prev,
            chatMessagesData: [...prev.chatMessagesData, newMessage],
          }));
          setTimeout(() => {
            scrollToBottom();
          }, 400);
        }
      });
    };

    chatMessages();

    if (socket) {
      return () => {
        socket?.off(`agent-new-chat-message-${chatRoomId}`);
        socket?.off(`agent-mark-as-read`);
      };
    }
  }, [chatRoomResponces, chatRoomId]);


  useEffect(() => {
    let socket = getSocket();
    const chatMessages = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket?.on(`agent-new-chat-room-${user_id}`, (data) => {
        if (data) {
          setAllChatRooms((prev) => {
            return [...prev, data?.chat_room];
          });
        }
      });
      if (role == "super_admin") {
        socket?.on("agent-new-chat-room", (data) => {
          if (data) {
            handleGetChatRoom(data?.chat_room?.id);
          }
        });
      }
    };
    chatMessages();
    if (socket) {
      return () => {
        socket?.off(`agent-new-chat-room-${user_id}`);

        if (role == "super_admin") {
          socket?.off("agent-new-chat-room");
        }
      };
    }
  }, [allChatRooms]);

  // 2 times api call to remove count
  useEffect(() => {
    if (chatRoomId) {
      let chatrooms = [...allChatRooms];
      for (let index in chatrooms) {
        let chatRoom = chatrooms[index];
        if (chatRoom.id == chatRoomId) {
          chatrooms[index].total_messages = 0;
          setType("notReload");
          setAllChatRooms(chatrooms);
          break;
        }
      }
    }
  }, [chatRoomId]);

  useEffect(() => {
    if (trigger === "countValueChanged") {
      handleGetChatRoom(chatRoomId);
      handlegetgetAllChatRooms();
      dispatch(changeTrigger(""));
    }
  }, [trigger]);

  useEffect(() => {
    role == "sale_agent" && dispatch(changeLoader(true));
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatRoomResponces?.chatMessagesData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      handlegetgetAllChatRooms();
    }, 400);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const handleFileChange = async (e) => {
    const files = e.target.files;
    const maxSize = 10 * 1024 * 1024;
    const maxFiles = 5;
    const allowedTypes = fileImageallowedTypes;
    // Validate files
    const { validFiles, invalidFiles } = validateFiles(
      files,
      allowedTypes,
      maxSize,
      maxFiles
    );
    // Handle invalid files
    if (invalidFiles.length > 0) {
      invalidFiles.forEach((errorMessage) => {
        toast.error(errorMessage);
      });
      return;
    }
    let uploadedFilesUrls = [];
    // Proceed with the valid files
    dispatch(changeLoader(true));
    if (validFiles.length > 0) {
      for (let i = 0; i < files?.length; i++) {
        if (files[i]) {
          const formdata = new FormData();
          formdata.append("file", files[i]);
          const fileUpload = await fileAzurUpload(formdata);
          if (fileUpload) {
            uploadedFilesUrls.push(fileUpload?.data?.url);
          } else {
            toast.error(fileUpload.message, {
              limit: 1,
              toastId: "imageUploadError",
            });
            dispatch(changeLoader(false));
          }
        }
      }
      dispatch(changeLoader(false));
      setUploadedImages(uploadedFilesUrls);
    }
  };
  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleImageRemove = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <Card>
        <div className="min-h-[calc(100vh_-_290px)] editor-height">
          <div className="flex items-center justify-between mb-4">
            <Typography className="text-lg font-semibold" align={"left"}>
              Messages
            </Typography>
          </div>
          {chatRoomResponces?.chatMessagesData?.length > 0 ? (
            <Row>
              <Col span={6}>
                <div className="Chat-list pt-5 min-h-[calc(100vh_-_150px)]">
                  {chatRoomResponces?.chatMessagesData?.length > 0 && (
                    <Form form={form} layout="vertical">
                      <Form.Item name="search">
                        <Search
                          allowClear
                          placeholder="Search by Client Name"
                          className="cursor-pointer w-full max-w-[calc(100%_-_10px)]"
                          onChange={(e) => {
                            let value = strFormat(e.target.value, "trim");
                            form.setFieldValue("search", value);
                            setSearch(value);
                          }}
                        />
                      </Form.Item>
                    </Form>
                  )}
                  <div className="scrollable-list">
                    <ul className="custom-chat-list">
                      {allChatRooms?.length > 0 ? (
                        allChatRooms.map((item, index) => (
                          <li
                            key={index}
                            className={`list-item has-message cursor-pointer ${chatRoomId === item?.id
                              ? "bg-[#0dbff1] text-white"
                              : ""
                              }`}
                            onClick={() => {
                              handleGetChatRoom(item?.id);
                              setChatRoomId(item?.id);
                              navigate(`/insurance-agent-view?id=${item?.id}`);
                            }}
                          >
                            <div className="list-item-meta">
                              {item?.chat_client?.profile_picture ? (
                                <img
                                  className="avatar"
                                  src={item?.chat_client?.profile_picture}
                                  alt="Avatar"
                                />
                              ) : (
                                <div className="avatar-default">
                                  {`${item?.chat_client?.full_name
                                    .split(" ")[0]
                                    .charAt(0)
                                    .toUpperCase()}${item?.chat_client?.full_name.split(" ")[1]
                                      ? item?.chat_client?.full_name
                                        .split(" ")[1]
                                        .charAt(0)
                                        .toUpperCase()
                                      : ""
                                    }`}
                                </div>
                              )}
                              <div>
                                <div className="title">
                                  {item?.chat_client?.full_name}
                                </div>
                                <div className="description">
                                  {item?.agent_chat_messages?.[0]?.message}
                                </div>
                              </div>
                            </div>
                            {item?.total_messages > 0 && (
                              <div className="notification-dot">
                                <span>{item?.total_messages}</span>
                              </div>
                            )}
                          </li>
                        ))
                      ) : (
                        <EmptyComponent description={"No chats found"} />
                      )}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col span={18}>
                <div className="mb-0">
                  <div className="w-full pt-1">
                    <div id="messages" className="px-4">
                      <div className="Chat-content">
                        <div className="scrollable-list">
                          <ul className="custom-chat-list">
                            <li className="list-item has-message">
                              {chatRoomResponces?.chatMessagesData?.length >
                                0 && (
                                  <div className="list-item-meta">
                                    {chatRoomResponces?.chatRoomData?.chat_client
                                      ?.profile_picture ? (
                                      <img
                                        className="avatar"
                                        src={
                                          chatRoomResponces?.chatRoomData
                                            ?.chat_agent?.profile_picture
                                        }
                                        alt="Avatar"
                                      />
                                    ) : (
                                      <div className="avatar-default">
                                        {chatRoomResponces?.chatRoomData?.chat_client?.full_name
                                          .split(" ")
                                          .map((name) =>
                                            name.charAt(0).toUpperCase()
                                          )
                                          .slice(0, 2)
                                          .join("")}
                                      </div>
                                    )}
                                    <div>
                                      <div className="title">
                                        {
                                          chatRoomResponces?.chatRoomData
                                            ?.chat_client?.full_name
                                        }
                                      </div>
                                      <div className="description">
                                        {
                                          chatRoomResponces?.chatRoomData
                                            ?.chat_client?.email
                                        }
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="h-[calc(100vh_-_290px)] overflow-y-auto pt-3">
                        {chatRoomResponces?.chatMessagesData?.length > 0 ? (
                          chatRoomResponces?.chatMessagesData?.map(
                            (item, index) => {
                              const isUserMessage = user_id === item?.sender_id;
                              return (
                                <div
                                  className="mb-4"
                                  key={index}
                                  ref={chatContainerRef}
                                >
                                  <div
                                    className={`flex ${isUserMessage
                                      ? "justify-end"
                                      : "justify-start"
                                      } items-center`}
                                  >
                                    {!isUserMessage && (
                                      <Avatar
                                        size={32}
                                        icon={
                                          chatRoomResponces?.chatRoomData
                                            ?.chat_client?.profile_picture ? (
                                            <img
                                              src={
                                                chatRoomResponces?.chatRoomData
                                                  ?.chat_client?.profile_picture
                                              }
                                              alt="Profile"
                                            />
                                          ) : (
                                            `${chatRoomResponces?.chatRoomData?.chat_client?.full_name
                                              ?.split(" ")[0]
                                              .charAt(0)
                                              .toUpperCase()}${chatRoomResponces?.chatRoomData?.chat_client?.full_name.split(
                                                " "
                                              )[1]
                                                ? chatRoomResponces?.chatRoomData?.chat_client?.full_name
                                                  .split(" ")[1]
                                                  .charAt(0)
                                                  .toUpperCase()
                                                : ""
                                            }`
                                          )
                                        }
                                        className="mr-2"
                                      />
                                    )}
                                    <div
                                      className={`w-auto max-w-[80%] min-w-[100px] break-all p-2 pb-4 rounded-lg relative ${isUserMessage
                                        ? "bg-[#0dbff1] text-white"
                                        : "bg-slate-100"
                                        }`}
                                    >
                                      <p className="text-sm mb-2">
                                        {item?.message}
                                      </p>
                                      <div className="flex flex-col gap-4">
                                        {item?.agent_chat_documents?.length > 0 &&
                                          item?.agent_chat_documents?.map((doc, index) => {
                                            const extension = doc?.document
                                              ?.split(".")
                                              .pop()
                                              .split("?")[0]
                                              .toLowerCase();
                                            return extension === "pdf" ||
                                              extension === "docx" || extension === "doc" ||
                                              extension === "xlsx" ||
                                              extension === "pptx" ? (
                                              <div
                                                key={index}
                                                className="pdf-preview"
                                              >
                                                <a
                                                  href={doc?.document}
                                                  // target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="flex items-center gap-2"
                                                >
                                                  <img
                                                    src={
                                                      extension === "pdf"
                                                        ? "/images/pdf-icon.png"
                                                        : extension === "xlsx"
                                                          ? "/images/xlsx.png"
                                                          : extension === "pptx"
                                                            ? "/images/pptx-file.png"
                                                            : "/images/word_icon.png"
                                                    }
                                                    alt="PDF"
                                                    width={40}
                                                    height={40}
                                                  />
                                                  <span
                                                    className={`${isUserMessage
                                                      ? "text-white"
                                                      : "text-black"
                                                      }`}
                                                  >
                                                    {extension === "pdf"
                                                      ? " View PDF File"
                                                      : (extension === "docx" || extension === "doc")
                                                        ? "View  Word File"
                                                        : (extension === "pptx" || extension === "ppt")
                                                          ? "View PPTX File"
                                                          : "View Excel File"}
                                                  </span>
                                                </a>
                                              </div>
                                            ) : (
                                              <Image
                                                key={index}
                                                src={doc?.document}
                                                alt={`uploaded-thumbnail-${item?.document}`}
                                                width={80}
                                                height={80}
                                                className="object-cover w-[80px] h-[80px]"
                                                preview={true}
                                              />
                                            );
                                          })}
                                      </div>
                                      <span
                                        className={`absolute right-2 bottom-1 text-[10px] ${isUserMessage
                                          ? "text-white"
                                          : "text-[#a8a8a8]"
                                          }`}
                                      >
                                        {DateHelper.format(
                                          item?.createdAt,
                                          "h:i A"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <EmptyComponent description="No chat message found" />
                        )}
                      </div>
                    </div>
                    {/* Input */}
                    {chatRoomResponces?.chatMessagesData?.length > 0 && (
                      <form
                        className="flex flex-col py-4 border-t border-gray-300 gap-3"
                        onSubmit={handleSendMessage}
                      >
                        {uploadedImages.length > 0 && (
                          <div className="flex flex-wrap gap-5">
                            {uploadedImages.map((image, index) => {
                              const extension = image
                                ?.split(".")
                                .pop()
                                ?.split("?")[0]
                                .toLowerCase();
                              return (
                                <div key={index} className="image-thumbnail relative">
                                  {["png", "jpg", "jpeg"].includes(extension) ? (
                                    <Image
                                      src={image}
                                      alt={`uploaded-thumbnail-${index}`}
                                      width={80}
                                      height={80}
                                      className="object-cover w-[80px] h-[80px]"
                                      preview={true}
                                    />
                                  ) : (
                                    // Show a placeholder icon for other extensions (e.g., docs, ppt)
                                    <div className="file-icon w-[80px] h-[80px] flex items-center justify-center bg-gray-200 ml-2">
                                      <a
                                        href={image}
                                        // target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center gap-2"
                                      >
                                        <img
                                          src={
                                            extension === "pdf"
                                              ? "/images/pdf-icon.png"
                                              : extension === "doc" || extension === "docx"
                                                ? "/images/word_icon.png"
                                                : extension === "ppt" || extension === "pptx"
                                                  ? "/images/pptx-file.png"
                                                  : "/images/xlsx.png"
                                          }
                                          alt={`file-icon-${extension}`}
                                          className="object-contain w-[50px] h-[50px]"
                                        />
                                      </a>
                                    </div>
                                  )}
                                  <CloseCircleOutlined
                                    className="remove-icon absolute top-[-6px] right-[-6px]"
                                    onClick={() => {
                                      handleImageRemove(index);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <div className="flex items-center gap-2">
                          <div className="w-full relative flex items-center">
                            <img
                              src="images/attach-file.png"
                              alt="attach-file"
                              className="w-6 h-6 cursor-pointer hover:opacity-80 mr-3"
                              onClick={handleFileClick}
                            />
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              multiple
                              onChange={handleFileChange}
                            />
                            <input
                              id="messageInput"
                              type="text"
                              placeholder="Type your message here..."
                              className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                              value={message}
                              onChange={(e) =>
                                setMessage(e.target.value?.trimStart())
                              }
                            />
                            <button
                              type="submit"
                              className={`ml-2 px-[14px] rounded-lg text-white h-full min-h-[39px] ${message || uploadedImages.length > 0
                                ? "bg-[#0dbff1]"
                                : "bg-gray-400 cursor-not-allowed"
                                }`}
                              disabled={!message && uploadedImages.length === 0}
                            >
                              <SendOutlined />
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          ) : (chatRoomResponces?.chatMessagesData?.length == 0 &&
            showStaticPage == true) ||
            role != "sale_agent" ? (
            <div className="flex flex-col items-center justify-center text-center">
              <img
                src="images/message.png"
                alt="logo"
                className="w-[160px]"
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(80%) sepia(50%) saturate(376%) hue-rotate(178deg)",
                }}
              />
              <h2 className="font-semibold text-2xl mb-4">No Messages, Yet.</h2>
              <p className="text-gray-500 text-base">
                No messages in your inbox, yet! Start chatting with people
                around you.
              </p>
            </div>
          ) : null}
        </div>
      </Card>
      <CloseChat
        ConfirmationHeading="Alert"
        ConfirmationParagraph="Are you sure you want to close the chat? This is end the current chat & you cannot reopen it."
        isOpen={isViewChatLogModalOpen}
        onOk={handleViewChatLogOk}
        onCancel={handleViewChatLogCancel}
      />
    </>
  );
};
export default InsuranceAgentView;
