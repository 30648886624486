import axiosInstance from "../AxiosInstance";

export const getProviderCategoriesList = (params) => {
  return axiosInstance.get(`/admin/category/list-category`, { params });
};

export const addProviderCategories = (params) => {
  return axiosInstance.post(`/admin/category/add-category`, params);
};

export const deleteProviderCategory = (id) => {
  return axiosInstance.put(`/admin/category/delete-category/${id}`);
};
