import { useEffect, useState } from "react";
import { Tooltip, Button, Pagination, Card, Typography, Input } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import SortingArrow from "../../Common/SortingArrow";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import EmptyComponent from "../Empty";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
import {
  deleteProviderCategory,
  getProviderCategoriesList,
} from "../../Axios/services/ProviderCategories";
import { toast } from "react-toastify";
import DateHelper from "../../Helpers/lib/DateHelper";
import { checkPermission } from "../../Helpers/Functions";

const { Search } = Input;
const ProviderCategories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const handleSort = () => {};
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const [providerCategoriesList, setProviderCategoriesList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [itemTotal, setItemTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  // Handle search input
  const handleSearch = (value, e) => {
    let searchText;
    if (typeof value === "object") {
      searchText = value.target.value.trimLeft();
    } else {
      searchText = value.trimLeft();
    }
    setSearchValue(searchText);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Get provider categories list based on search, and pagination
  const getProviderCategoriesLists = async () => {
    dispatch(changeLoader(true));
    try {
      const params = {
        search: searchValue,
        page: searchValue.length > 2 ? 1 : page,
        limit: limit,
      };
      const response = await getProviderCategoriesList(params);
      console.log(response);
      if (response.ack) {
        setProviderCategoriesList(response.data);
        setItemTotal(response.totalData);
        dispatch(changeLoader(false));
      } else {
        dispatch(changeLoader(false));
        toast.error(response.message, {
          limit: 1,
        });
      }
    } catch (error) {
      console.error("Error fetching notification list:", error);
      toast.error(error.message, {
        limit: 1,
      });
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    getProviderCategoriesLists();
  }, [page, limit]);

  useEffect(() => {
    if (searchValue.length > 2 || searchValue === "") {
      getProviderCategoriesLists();
    }
  }, [searchValue]);

  const handleSizeChange = (current, size) => {
    setLimit(size);
    setPage(1);
  };

  const handleDeleteCategory = async () => {
    dispatch(changeLoader(true));
    try {
      const response = await deleteProviderCategory(deleteId);
      if (response.ack) {
        toast.success("Category deleted successfully.");
        // Calculate if we need to go back a page
        const remainingItems = itemTotal - 1;
        const totalPages = Math.ceil(remainingItems / limit);
        if (page > totalPages && totalPages > 0) {
          setPage(totalPages);
        }
        getProviderCategoriesLists();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Failed to delete category. Please try again.");
    } finally {
      dispatch(changeLoader(false));
      setIsDeleteModalOpen(false);
    }
  };


  return (
    <>
      <Card>
        <div className="flex items-center justify-between relative mb-4">
          <Typography className="text-xl font-semibold mb-0" align={"left"}>
            Provider Category
          </Typography>
          <div className="flex justify-end gap-3">
            <div className="text-[14px] font-semibold flex items-center whitespace-nowrap">
              Total Provider Category - {itemTotal}
            </div>
            {/* <Search
              allowClear
              placeholder="Search by category name"
              style={{
                width: 250,
              }}
              className="mx-1.5 cursor-pointer"
              onSearch={handleSearch}
              value={searchValue}
              onChange={handleSearch}
            /> */}
            {checkPermission("category", "add") && (
              <Button
                type="primary"
                onClick={() => navigate("/add-categories")}
                icon={<PlusOutlined />}
              >
                Add New
              </Button>
            )}
          </div>
        </div>

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Display Name{" "}
                  {/* <SortingArrow
                  // onSort={() => {
                  //   handleSort();
                  // }}
                  /> */}
                </th>

                <th scope="col" className="px-3 py-3">
                  Search Query{" "}
                  {/* <SortingArrow
                  // onSort={() => {
                  //   handleSort();
                  // }}
                  /> */}
                </th>
                <th scope="col" className="px-3 py-3">
                  Added on{" "}
                  {/* <SortingArrow
                  // onSort={() => {
                  //   handleSort();
                  // }}
                  /> */}
                </th>
                <th scope="col" className="px-3 py-3">
                  Added by{" "}
                  {/* <SortingArrow
                  onSort={() => {
                    handleSort();
                  }}
                  /> */}
                </th>
                {checkPermission("category", "delete") && (
                  <th
                    scope="col"
                    className="px-3 py-3 text-center whitespace-nowrap"
                  >
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {providerCategoriesList?.length > 0 ? (
                providerCategoriesList?.map((categories) => (
                  <>
                    <tr className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top capitalize"
                      >
                        {categories?.display_name}
                      </th>
                      <td className="px-3 py-4 vertical-top">
                        {categories?.search_query}
                      </td>
                      <td className="px-3 py-4 vertical-top">
                        {DateHelper.format(
                          categories?.createdAt,
                          "m/d/Y h:i A"
                        )}
                      </td>
                      <td className="px-3 py-4 vertical-top">
                        {categories?.added_by_name}
                      </td>
                      {checkPermission("category", "delete") && (
                        <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                          <Tooltip title="Delete">
                            <DeleteOutlined
                              onClick={() => {
                                setDeleteId(categories.id);
                                setIsDeleteModalOpen(true);
                              }}
                              className="mx-1.5 cursor-pointer"
                            />
                          </Tooltip>
                        </td>
                      )}
                    </tr>
                  </>
                ))
              ) : (
                <td colSpan={6} className="text-center">
                  <EmptyComponent description={"No Category Found"} />
                </td>
              )}
            </tbody>
          </table>
        </div>
        {providerCategoriesList?.length > 0 && itemTotal > 15 && (
          <div className="text-right mt-4">
            <Pagination
              current={page}
              pageSize={limit}
              total={itemTotal}
              onChange={handlePageChange}
              onShowSizeChange={handleSizeChange}
              showSizeChanger={true}
              pageSizeOptions={[15, 25, 50, 100]}
            />
          </div>
        )}
      </Card>

      {/* delete modal */}
      <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Provider Category?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteCategory}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default ProviderCategories;
