import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Divider, Typography, Col, Row } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getNotificationDetails } from "../../Axios/services/Notification";
import { changeLoader } from "../../Redux/reducers/loader";
import DateHelper from "../../Helpers/lib/DateHelper";
import { capitlizeFirstLetter } from "../../Helpers/lib/StringHelper";

export const getfeildText = (feild) => {
  const statusMap = {
    specific_carrier: "Specific Carrier",
    state: "State",
    city: "City",
    zip_code: "Zip Code",
    age: "Age",
  };
  return statusMap[feild] || "";
};

const ViewNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [notificationData, setNotificationData] = React.useState({});
  //get notification data
  const notificationDetails = async (id) => {
    dispatch(changeLoader(true));
    try {
      const response = await getNotificationDetails(id);
      if (response.ack) {
        setNotificationData(response.data);
        dispatch(changeLoader(false));
      }
    } catch (error) {
      console.error("Error fetching notification details:", error);
      dispatch(changeLoader(false));
    } finally {
      dispatch(changeLoader(false));
    }
  };
  React.useEffect(() => {
    if (slug) {
      notificationDetails(slug);
    }
  }, [slug]);

  return (
    <Card>
      <div className="flex justify-between items-center mb-4 gap-3">
        <div>
          <Typography className="text-xl font-semibold" align={"left"}>
            <LeftOutlined onClick={() => navigate(-1)} className="mr-2" />
            View Notification
          </Typography>
        </div>
      </div>
      <Divider />
      <div className="max-w-5xl	">
        <Row gutter={[40, 16]}>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={24}
          >
            <div className="text-base font-medium text-black min-w-[250px]">
              User type
            </div>
            <div className="text-base">
              {notificationData?.user_type === "all" ? "All Client" : "Custom"}
            </div>
          </Col>
          {notificationData?.user_type === "custom" && (
            <>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={24}
              >
                <div className="text-base font-medium text-black min-w-[250px]">
                  Field
                </div>
                <div className="text-base">
                  {notificationData?.field_type &&
                    getfeildText(notificationData?.field_type)}
                </div>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={24}
              >
                <div className="text-base font-medium text-black min-w-[250px]">
                  Value
                </div>
                <div className="text-base">
                  {notificationData?.field_value}{" "}
                </div>
              </Col>
            </>
          )}
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={24}
          >
            <div className="text-base font-medium text-black min-w-[250px]">
              Title
            </div>
            <div className="text-base">
              {notificationData?.title &&
                capitlizeFirstLetter(notificationData?.title)}
            </div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={24}
          >
            <div className="text-base font-medium text-black min-w-[250px]">
              Message
            </div>
            <div className="text-base">
              {notificationData?.message &&
                capitlizeFirstLetter(notificationData?.message)}
            </div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={24}
          >
            <div className="text-base font-medium text-black min-w-[250px]">
              Sent on
            </div>
            <div className="text-base">
              {DateHelper.format(notificationData?.createdAt, "m/d/Y h:i A")}
            </div>
          </Col>
          <Col
            className="gutter-row flex items-start justify-start"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={24}
          >
            <div className="text-base font-medium text-black min-w-[250px]">
              Sent by
            </div>
            <div className="text-base">{notificationData?.sent_by_name}</div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default ViewNotification;
