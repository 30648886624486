import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Divider,
  Typography,
  Col,
  Row,
  Switch,
  Upload,
  Button,
  Form,
  Input,
  DatePicker,
} from "antd";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { LeftOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import Authorization from "./ViewClientManagementTabs/Authorization";
import Policy from "./ViewClientManagementTabs/Policy";
import {
  UpdateClientProfile,
  UpdateCustomerStatus,
  getClient_Detail,
} from "../../Axios/services/ClientManagement";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
import DateHelper from "../../Helpers/lib/DateHelper";
import { calculateAge, checkPermission } from "../../Helpers/Functions";
import { toast } from "react-toastify";
import ViewAuthorization from "../../Modal/ViewAuthorization";
import ValidationHelper, { isEmail } from "../../Helpers/lib/ValidationHelper";
const { TabPane } = Tabs;
const ViewClientManagement = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewAuthorization, setIsViewAuthorization] = useState(false);
  const [isEditClientDet, setIsEditClientDet] = useState(false);
  const { slug } = useParams();
  let [responcess, setResponce] = useState({});
  let [policies, setPolicies] = useState([]);
  const [formData, setFormData] = useState({
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    clientDob: "",
    clientIncome: "",
  });

  const [errorData, setErrorData] = useState({
    clientNameError: "",
    clientEmailError: "",
    clientPhoneError: "",
    clientDobError: "",
    clientIncomeError: "",
  });

  const dispatch = useDispatch();
  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handleViewAuthorizationOk = () => {
    setIsViewAuthorization(false);
  };
  const handleViewAuthorizationCancel = () => {
    setIsViewAuthorization(false);
  };
  const handleGetClientDetail = async () => {
    dispatch(changeLoader(true));
    try {
      const response = await getClient_Detail(slug);
      if (response.ack) {
        setResponce(response?.data);
        setPolicies(response?.policies);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.error(error);
    }
  };

  const handleUpdateStatus = async (id) => {
    dispatch(changeLoader(true));
    try {
      let params = {
        status: responcess?.status === "active" ? "inactive" : "active",
      };
      let responce = await UpdateCustomerStatus(id, params);
      if (responce.ack) {
        handleGetClientDetail();
      }
      toast[responce.ack ? "success" : "error"](responce.message, {
        limit: 1,
        toastId: "forgotPassword" + (responce.ack ? "Success" : "Error"),
      });
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleGetClientDetail();
  }, []);

  const handleEditClient = () => {
    setIsEditClientDet(true);
    setFormData({
      clientName: responcess?.full_name,
      clientEmail: responcess?.email,
      clientPhone: responcess?.contact_number,
      clientDob: new Date(responcess?.date_of_birth)
        .toISOString()
        .split("T")[0],
      clientIncome: responcess?.income,
    });
  };

  // Handle error
  const handleError = () => {
    let error = false;
    // Validate client_name
    if (!formData.clientName) {
      setErrorData((prev) => ({
        ...prev,
        clientNameError: "Please enter client name",
      }));
      error = true;
    } else if (
      formData.clientName.length < 3 ||
      formData.clientName.length > 50
    ) {
      setErrorData((prev) => ({
        ...prev,
        clientNameError: "Name must be between 3 and 50 characters",
      }));
      error = true;
    }

    // Validate client_email
    if (!formData.clientEmail) {
      setErrorData((prev) => ({
        ...prev,
        clientEmailError: "Please enter client email",
      }));
      error = true;
    } else if (!ValidationHelper.isEmail(formData.clientEmail)) {
      setErrorData((prev) => ({
        ...prev,
        clientEmailError: "Invalid email address",
      }));
      error = true;
    }
    // Validate client_phone
    if (!formData.clientPhone) {
      setErrorData((prev) => ({
        ...prev,
        clientPhoneError: "Please enter client phone",
      }));
      error = true;
    } else if (!/^\d{10}$/.test(formData.clientPhone)) {
      setErrorData((prev) => ({
        ...prev,
        clientPhoneError: "Phone number should be 10 digits",
      }));
      error = true;
    }
    // Validate client_dob
    if (!formData.clientDob) {
      setErrorData((prev) => ({
        ...prev,
        clientDobError: "Please enter client date of birth",
      }));
      error = true;
    }
    if (DateHelper.isFutureDate(formData.clientDob)) {
      setErrorData((prev) => ({
        ...prev,
        clientDobError: "Date of birth cannot be a future date",
      }));
      error = true;
    }
    // Validate client_income
    if (!formData.clientIncome) {
      setErrorData((prev) => ({
        ...prev,
        clientIncomeError: "Please enter client income",
      }));
      error = true;
    }
    return error;
  };

  const handleSubmit = async (id) => {
    if (!handleError()) {
      dispatch(changeLoader(true));
      try {
        let params = {
          full_name: formData?.clientName,
          email: formData?.clientEmail,
          contact_number: formData?.clientPhone,
          date_of_birth: formData?.clientDob,
          income: formData?.clientIncome,
        };
        let responce = await UpdateClientProfile(id, params);
        if (responce.ack) {
          handleGetClientDetail();
          setIsEditClientDet(false);
        }
        toast[responce.ack ? "success" : "error"](responce.message, {
          limit: 1,
        });
        dispatch(changeLoader(false));
      } catch (err) {
        console.log(err);
        dispatch(changeLoader(false));
      }
    }
  };

  const handleCancel = () => {
    setIsEditClientDet(false);
    setErrorData({
      clientNameError: "",
      clientEmailError: "",
      clientPhoneError: "",
      clientDobError: "",
    });
  };

  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined onClick={() => navigate(-1)} className="mr-2" />
              Client Detail
            </Typography>
          </div>
          <div className="flex justify-end gap-3 items-center">
            <Switch
              checked={responcess?.status == "active" ? true : false}
              onChange={() => {
                handleUpdateStatus(slug);
              }}
              disabled={!checkPermission("manage_client", "update_status")}
            />
          </div>
        </div>
        <Divider />
        {!isEditClientDet && (
          <div className="flex justify-end mb-5">
            {checkPermission("manage_client", "update_client_profile") && (
              <Button
                type="primary"
                onClick={() => {
                  handleEditClient();
                }}
              >
                Edit Client Details
              </Button>
            )}
          </div>
        )}
        <div className="w-full">
          <div className="rounded-md p-4 border flex flex-col gap-1">
            <div className="flex mb-8">
              <div className=""></div>
              <div>
                <Row gutter={[40, 16]}>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-full max-w-[160px]">
                      Client ID :{" "}
                    </div>
                    <div className="text-base">{responcess?.id}</div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="gutter-row flex items-start justify-start">
                      <div className="text-base font-medium text-black w-full max-w-[160px]">
                        Client name{isEditClientDet && <span style={{ color: "red" }}>*</span>}  :{" "}
                      </div>
                      {isEditClientDet ? (
                        <div className="w-full">
                          <Input
                            size="large"
                            type="text"

                            value={formData?.clientName}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                clientName: e.target.value,
                              });
                              setErrorData((prev) => ({
                                ...prev,
                                clientNameError: "",
                              }));
                            }}
                          />
                          <span className="text-red-500">
                            {errorData.clientNameError}
                          </span>
                        </div>
                      ) : (
                        <div className="text-base">{responcess?.full_name}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="gutter-row flex items-start justify-start">
                      <div className="text-base font-medium text-black w-full max-w-[160px]">
                        Email address{isEditClientDet && <span style={{ color: "red" }}>*</span>} :{" "}
                      </div>
                      {isEditClientDet ? (
                        <div className="w-full">
                          <Input
                            size="large"
                            type="text"
                            value={formData?.clientEmail}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                clientEmail: e.target.value,
                              });
                              setErrorData((prev) => ({
                                ...prev,
                                clientEmailError: "",
                              }));
                            }}
                          />
                          <span className="text-red-500">
                            {errorData.clientEmailError}
                          </span>
                        </div>
                      ) : (
                        <div className="text-base">{responcess?.email}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="gutter-row flex items-start justify-start">
                      <div className="text-base font-medium text-black w-full max-w-[160px]">
                        Mobile number{isEditClientDet && <span style={{ color: "red" }}>*</span>} :{" "}
                      </div>
                      {isEditClientDet ? (
                        <div className="w-full">
                          <Input
                            size="large"
                            type="text"
                            value={formData?.clientPhone}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                clientPhone: e.target.value,
                              });
                              setErrorData((prev) => ({
                                ...prev,
                                clientPhoneError: "",
                              }));
                            }}
                          />
                          <span className="text-red-500">
                            {errorData.clientPhoneError}
                          </span>
                        </div>
                      ) : (
                        <div className="text-base">
                          {responcess?.contact_number}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-full max-w-[160px]">
                      {isEditClientDet ? "DOB" : "Age"}{isEditClientDet && <span style={{ color: "red" }}>*</span>} :{" "}
                    </div>
                    {isEditClientDet ? (
                      <>
                        {responcess?.date_of_birth && (
                          <input
                            type="date"
                            className="w-full px-4 py-2 text-base border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white hover:bg-gray-50 transition-colors"
                            value={formData?.clientDob}
                            max={new Date().toISOString().split('T')[0]}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                clientDob: e.target.value,
                              });
                              setErrorData((prev) => ({
                                ...prev,
                                clientDobError: "",
                              }));
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <div className="text-base">
                        {responcess?.date_of_birth
                          ? `${calculateAge(responcess.date_of_birth)} Years`
                          : "-"}
                      </div>
                    )}
                    <span className="text-red-500">
                      {errorData.clientDobError}
                    </span>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="gutter-row flex items-start justify-start">
                      <div className="text-base font-medium text-black w-full max-w-[160px] whitespace-nowrap">
                        Income{isEditClientDet && <span style={{ color: "red" }}>*</span>} :{" "}
                      </div>
                      {isEditClientDet ? (
                        <div className="w-full">
                          <Input
                            size="large"
                            type="text"
                            className="rounded-md border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                            value={formData?.clientIncome}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === '' || /^\d*\.?\d*$/.test(value)) {
                                setFormData({
                                  ...formData,
                                  clientIncome: value,
                                });
                                setErrorData((prev) => ({
                                  ...prev,
                                  clientIncomeError: "",
                                }));
                              }
                            }}
                          />
                          <span className="text-red-500">
                            {errorData.clientIncomeError}
                          </span>
                        </div>
                      ) : (
                        <div className="text-base text-gray-700 break-words">
                          {`$${responcess?.income}`}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-full max-w-[160px]">
                      Joined on :{" "}
                    </div>
                    <div className="text-base">
                      {DateHelper.format(responcess?.createdAt, "m/d/Y")}
                    </div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-full max-w-[160px]">
                      Last updated on :{" "}
                    </div>
                    <div className="text-base">
                      {DateHelper.format(responcess?.updatedAt, "m/d/Y")}
                    </div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-full max-w-[160px]">
                      Address :{" "}
                    </div>
                    {responcess?.UserAddresses?.length > 0 ? (
                      <div className="flex flex-col gap-2 max-h-[200px] overflow-y-auto">
                        {responcess.UserAddresses.map((address, index) => (
                          <div
                            key={index}
                            className="text-bas"
                          >
                            {`${address?.address}, ${address?.city}, ${address?.state}, ${address?.zip_code}, ${address?.county}`}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-base">-</div>
                    )}
                  </Col>
                </Row>
                <div className="flex justify-center gap-3 mt-5">
                  {isEditClientDet && (
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleSubmit(slug);
                        }}
                        disabled={
                          !formData.clientName ||
                          !formData.clientPhone ||
                          !formData.clientEmail ||
                          !formData?.clientDob
                        }
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Tabs defaultActiveKey="1">
              {" "}
              {checkPermission("manage_client", "view-policy") && (
                <TabPane tab="Policy" key="1">
                  <Policy responcess={responcess} policies={policies} getClientDetail={handleGetClientDetail} />
                </TabPane>
              )}
              {checkPermission("manage_client", "authorization-log") && (
                <TabPane tab="Authorization Logs" key="2">
                  {" "}
                  <Authorization responcess={responcess} />
                </TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </Card>
      {/* delete modal */}
      <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Admin?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <ViewAuthorization
        isOpen={isViewAuthorization}
        onOk={handleViewAuthorizationOk}
        onCancel={handleViewAuthorizationCancel}
      />
    </>
  );
};

export default ViewClientManagement;
