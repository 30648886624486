import axiosInstance from "../AxiosInstance";

export const getchatqueue = (params) => {
    return axiosInstance.get('/admin/chat/get', { params })
}

export const getchatroom_message = (id) => {
    return axiosInstance.get(`/admin/chat/room/message/${id}`,)
}

export const AssignSelf = (data) => {
    return axiosInstance.post('/admin/chat/assign-self', data)
}

export const AssignAgent = (data) => {
    return axiosInstance.post('/admin/chat/assign-agent', data)
}

export const getAssignAgent = () => {
    return axiosInstance.get('/admin/chat/get-available-agents')
}
