import React, { useState } from "react";
import {
  Card,
  Divider,
  Typography,
  Col,
  Row,
  Image,
  Spin,
  Button,
  Input,
} from "antd";
import DateHelper from "../../../Helpers/lib/DateHelper";
import fileAzurUpload from "../../../Axios/UploadFile";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { changeLoader } from "../../../Redux/reducers/loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import noDigital from "../../../images/no-digital-card.jpg";
import {
  updateClientPolicyDetails,
  uploadPolicyDigitalCard,
} from "../../../Axios/services/ClientManagement";
import { checkPermission } from "../../../Helpers/Functions";
const PolicyDetailsComponent = ({ policy, responcess, getClientDetail }) => {
  const dispatch = useDispatch();
  const [isEditPolicy, setIsEditPolicy] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(
    policy?.policy_card_doc ? policy?.policy_card_doc : noDigital
  );
  const [imageLoading, setImageLoading] = useState(false);
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    // Validate file format (only PNG and JPG)
    const allowedFormats = ["image/png", "image/jpeg"];
    if (!allowedFormats.includes(file.type)) {
      toast.error("Only PNG and JPG files are allowed!", {
        limit: 1,
        toastId: "imageError",
      });
      return;
    }
    // Validate file size (max 10MB)
    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("File size must be less than 10MB!", {
        limit: 1,
        toastId: "imageError",
      });
      return;
    }
    dispatch(changeLoader(true));
    setImageLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    // Upload the file
    const fileUpload = await fileAzurUpload(formData);
    if (fileUpload && fileUpload.data && fileUpload.data.url) {
      setUploadedImage(fileUpload.data.url);
      await uploadDigitalCard({
        policy_card_doc: fileUpload?.data?.url,
        policy_id: policy?.id,
      });
      dispatch(changeLoader(false));
    } else {
      toast.error("Failed to upload digital card image.", {
        limit: 1,
        toastId: "imageUploadError",
      });
      dispatch(changeLoader(false));
    }
  };
  const uploadDigitalCard = async (data) => {
    dispatch(changeLoader(true));
    try {
      const responce = await uploadPolicyDigitalCard(data);
      if (responce?.ack === true) {
        toast.success("Digital card image uploaded successfully!", {
          limit: 1,
          toastId: "imageUploadSuccess",
        });
        dispatch(changeLoader(false));
      } else {
        setUploadedImage(
          policy?.policy_card_doc ? policy?.policy_card_doc : noDigital
        );
        dispatch(changeLoader(false));
        toast.error("Failed to upload digital card image.", {
          limit: 1,
          toastId: "imageUploadError",
        });
      }
    } catch (error) {
      setUploadedImage(null);
      console.log(error);
      toast.error("Failed to upload digital card image.", {
        limit: 1,
        toastId: "imageUploadError",
      });
      setUploadedImage(
        policy?.policy_card_doc ? policy?.policy_card_doc : noDigital
      );
      dispatch(changeLoader(false));
    }
  };
  const [formData, setFormData] = useState({
    policyEffectiveCoverageDate: "",
    policyValidTill: "",
    policyPremium: "",
    policyPremiumDue: "",
    policyDoctorVisit: "",
    policySpecialist: "",
    policySubsidy: "",
  });
  const [errorData, setErrorData] = useState({
    policyValidTillError: "",
    polictPremiumError: "",
    policyPremiumDueError: "",
    policyMaxOutofPocketError: "",
    policyDeductibleError: "",
    policyDoctorVisitError: "",
    policySpecialistError: "",
    policySubsidyError: "",
  });
  const handleUpdatePolicy = () => {
    setIsEditPolicy(true);
    setFormData({
      policyEffectiveCoverageDate: new Date(policy?.policy_effective_date)
        .toISOString()
        .split("T")[0],
      policyValidTill: new Date(policy?.policy_expiry_date)
        .toISOString()
        .split("T")[0],
      policyPremium: policy?.policy_premium,
      policyPremiumDue: policy?.policy_premium_due,
      policyMaxOutofPocket: policy?.max_out_of_pocket,
      policyDeductible: policy?.policy_deductible,
      policyDoctorVisit: policy?.doctors_visit,
      policySpecialist: policy?.specialist_visit,
      policySubsidy: policy?.subsidy,
    });
  };
  // Handle error
  const handleError = () => {
    let error = false;
    if (!formData.policyValidTill) {
      setErrorData((prev) => ({
        ...prev,
        policyValidTillError: "Please enter policy valid till",
      }));
      error = true;
    }
    if (!formData.policyPremium) {
      setErrorData((prev) => ({
        ...prev,
        polictPremiumError: "Please enter policy premium",
      }));
      error = true;
    }
    if (!formData.policyPremiumDue) {
      setErrorData((prev) => ({
        ...prev,
        policyPremiumDueError: "Please enter policy premium due",
      }));
      error = true;
    }
    if (!formData.policyDoctorVisit) {
      setErrorData((prev) => ({
        ...prev,
        policyDoctorVisitError: "Please enter policy doctor visit",
      }));
      error = true;
    }
    if (!formData.policySpecialist) {
      setErrorData((prev) => ({
        ...prev,
        policySpecialistError: "Please enter policy specialist",
      }));
      error = true;
    }
    if (!formData.policySubsidy) {
      setErrorData((prev) => ({
        ...prev,
        policySubsidyError: "Please enter policy subsidy",
      }));
      error = true;
    }
    return error;
  };

  const handleSubmit = async (id) => {
    if (!handleError()) {
      dispatch(changeLoader(true));
      try {
        let params = {
          policy_effective_date: formData?.policyEffectiveCoverageDate,
          policy_expiry_date: formData?.policyValidTill,
          policy_premium: formData?.policyPremium,
          policy_premium_due: formData?.policyPremiumDue,
          max_out_of_pocket: formData?.policyMaxOutofPocket,
          policy_deductible: formData?.policyDeductible,
          doctors_visit: formData?.policyDoctorVisit,
          specialist_visit: formData?.policySpecialist,
          subsidy: formData?.policySubsidy,
        };
        let responce = await updateClientPolicyDetails(id, params);
        if (responce.ack) {
          toast[responce.ack ? "success" : "error"](responce.message, {
            limit: 1,
          });
          getClientDetail();
          dispatch(changeLoader(false));
          setIsEditPolicy(false);
        }
      } catch (err) {
        console.log(err);
        dispatch(changeLoader(false));
      }
    }
  };

  const handleCancel = () => {
    setIsEditPolicy(false);
    // Clear all errors
    setErrorData({
      policyValidTillError: "",
      polictPremiumError: "",
      policyPremiumDueError: "",
      policyMaxOutofPocketError: "",
      policyDeductibleError: "",
      policyDoctorVisitError: "",
      policySpecialistError: "",
      policySubsidyError: "",
    });
  };

  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <Typography className="text-xl font-semibold" align={"left"}>
            Policy Detail
          </Typography>
          {!isEditPolicy && (
            <div className="flex justify-end">
              {checkPermission("manage_client", "update_client_policy") && (
                <Button
                  type="primary"
                  onClick={() => {
                    handleUpdatePolicy();
                  }}
                >
                  Edit Policy
                </Button>
              )}
            </div>
          )}
        </div>
        <Divider />
        <div className="w-full">
          <div>
            <div className="upload-container flex items-start justify-start gap-4">
              {/* If an image is uploaded */}
              <div className="flex flex-wrap gap-4">
                {uploadedImage && (
                  <div className="image-container relative">
                    <Image
                      src={uploadedImage ? uploadedImage : noDigital}
                      alt="Uploaded Image"
                      className="uploaded-image mb-[14px] !object-cover"
                      loading="lazy"
                      width={140}
                      height={140}
                      preview={true}
                      onLoad={() => setImageLoading(false)}
                    />
                    {imageLoading && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-10">
                        <Spin size="large" />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <label htmlFor="file-upload" className="custom-file-upload">
                <UploadOutlined className="mx-0 mb-2 text-[24px]" />
                <span>Click to Upload</span>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          </div>
          <div className="flex mb-8">
            <Row gutter={[40, 16]}>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  ID :{" "}
                </div>
                <div className="text-base">
                  {policy?.policy_number ? policy?.policy_number : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Subscriber :{" "}
                </div>
                <div className="text-base">{responcess?.full_name}</div>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Member :{" "}
                </div>
                <div className="text-base">{responcess?.full_name}</div>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Effective coverage date :{" "}
                </div>
                {isEditPolicy ? (
                  <input
                    type="date"
                    className="w-full px-4 py-2 text-base border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white hover:bg-gray-50 transition-colors"
                    value={formData?.policyEffectiveCoverageDate}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        policyEffectiveCoverageDate: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.policy_effective_date
                      ? DateHelper.format(
                        policy?.policy_effective_date,
                        "m/d/Y"
                      )
                      : "-"}
                  </div>
                )}
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Policy valid till :{" "}
                </div>

                {isEditPolicy ? (
                  <input
                    type="date"
                    className="w-full px-4 py-2 text-base border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white hover:bg-gray-50 transition-colors"
                    value={formData?.policyValidTill}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        policyValidTill: e.target.value,
                      });
                      setErrorData({
                        ...errorData,
                        policyValidTillError: "",
                      });
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.policy_expiry_date
                      ? DateHelper.format(policy?.policy_expiry_date, "m/d/Y")
                      : "-"}
                  </div>
                )}
                <span className="text-red-500">
                  {errorData.policyValidTillError}
                </span>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Premium :{" "}
                </div>
                {isEditPolicy ? (
                  <Input
                    size="large"
                    type="text"
                    value={formData?.policyPremium}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        setFormData({
                          ...formData,
                          policyPremium: value,
                        });
                        setErrorData((prev) => ({
                          ...prev,
                          polictPremiumError: "",
                        }));
                      }
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.policy_premium
                      ? `$${policy?.policy_premium}`
                      : "$0.0"}
                  </div>
                )}
                <span className="text-red-500">
                  {errorData.polictPremiumError}
                </span>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Premium Due :{" "}
                </div>

                {isEditPolicy ? (
                  <Input
                    size="large"
                    type="text"
                    value={formData?.policyPremiumDue}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        setFormData({
                          ...formData,
                          policyPremiumDue: value,
                        });
                        setErrorData((prev) => ({
                          ...prev,
                          policyPremiumDueError: "",
                        }));
                      }
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.policy_premium_due
                      ? `$${policy?.policy_premium_due}`
                      : "$0.0"}
                  </div>
                )}
                <span className="text-red-500">
                  {errorData.policyPremiumDueError}
                </span>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Max out of pocket :{" "}
                </div>

                {isEditPolicy ? (
                  <Input
                    size="large"
                    type="text"
                    value={formData?.policyMaxOutofPocket}
                    onChange={(e) => {
                      const numericValue = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      );
                      setFormData({
                        ...formData,
                        policyMaxOutofPocket: numericValue.toString(),
                      });
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.max_out_of_pocket
                      ? `$${policy?.max_out_of_pocket}`
                      : "-"}
                  </div>
                )}
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Deductible :{" "}
                </div>

                {isEditPolicy ? (
                  <Input
                    size="large"
                    type="text"
                    value={formData?.policyDeductible}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        setFormData({
                          ...formData,
                          policyDeductible: value,
                        });
                      }
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.policy_deductible
                      ? `$${policy?.policy_deductible}`
                      : "-"}
                  </div>
                )}
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Doctor's Visit :{" "}
                </div>

                {isEditPolicy ? (
                  <Input
                    size="large"
                    type="text"
                    value={formData?.policyDoctorVisit}
                    onChange={(e) => {
                      const numericValue = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      );
                      setFormData({
                        ...formData,
                        policyDoctorVisit: numericValue.toString(),
                      });
                      setErrorData((prev) => ({
                        ...prev,
                        policyDoctorVisitError: "",
                      }));
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.doctors_visit ? `${policy.doctors_visit}` : "-"}
                  </div>
                )}
                <span className="text-red-500">
                  {errorData.policyDoctorVisitError}
                </span>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Specialist :{" "}
                </div>
                {isEditPolicy ? (
                  <Input
                    size="large"
                    type="text"
                    value={formData?.policySpecialist}
                    onChange={(e) => {
                      const numericValue = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      );
                      setFormData({
                        ...formData,
                        policySpecialist: numericValue.toString(),
                      });
                      setErrorData((prev) => ({
                        ...prev,
                        policySpecialistError: "",
                      }));
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.specialist_visit
                      ? `${policy.specialist_visit}`
                      : "-"}
                  </div>
                )}
                <span className="text-red-500">
                  {errorData.policySpecialistError}
                </span>
              </Col>
              <Col
                className="gutter-row flex items-start justify-start"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="text-base font-medium text-black w-[250px]">
                  Subsidy :{" "}
                </div>
                {isEditPolicy ? (
                  <Input
                    size="large"
                    type="text"
                    value={formData?.policySubsidy}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        setFormData({
                          ...formData,
                          policySubsidy: value,
                        });
                        setErrorData((prev) => ({
                          ...prev,
                          policySubsidyError: "",
                        }));
                      }
                    }}
                  />
                ) : (
                  <div className="text-base">
                    {policy?.subsidy ? `$${policy.subsidy}` : "-"}
                  </div>
                )}
                <span className="text-red-500">
                  {errorData.policySubsidyError}
                </span>
              </Col>
            </Row>
          </div>
          <div className="flex justify-center gap-3 mt-5">
            {isEditPolicy && (
              <>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleSubmit(policy?.id);
                  }}
                  disabled={!formData.policyValidTill}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default PolicyDetailsComponent;
