import axiosInstance from "../AxiosInstance";

export const getAllClients = (params) => {
  return axiosInstance.get("/admin/customers/getAllCustomers", { params });
};

export const getClient_Detail = (id) => {
  return axiosInstance.get(`/admin/customers/get/${id}`);
};

export const UpdateCustomerStatus = (id, data) => {
  return axiosInstance.patch(`/admin/customers/update/${id}`, data);
};

export const UpdateClientProfile = (id, data) => {
  return axiosInstance.put(`/admin/customers/edit-user/${id}`, data);
};

export const exportAllData = (params) => {
  return axiosInstance.get(`/admin/customers/export`, { params });
};

export const Resend_Password = (id) => {
  return axiosInstance.get(`/admin/resendPassword/${id}`);
};

export const getAuthorization = (id, params) => {
  return axiosInstance.get(`/admin/authorization/get/${id}`, { params });
};

export const getAuthorizationDetail = (id) => {
  return axiosInstance.get(`/admin/authorization/getDetails/${id}`);
};

export const uploadPolicyDigitalCard = (data) => {
  return axiosInstance.put(`/admin/policy/uploadPolicyCard`, data);
};

export const updateClientPolicyDetails = (id, data) => {
  return axiosInstance.put(`/admin/policy/update-policy/${id}`, data);
};

export const updateClientPolicyDepedentDetails = (id, data) => {
  return axiosInstance.put(`/admin/policy/update-policy-dependent/${id}`, data);
};
