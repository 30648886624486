import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import HeaderComponent from "./Layout/HeaderComponent";
import LeftSidebar from "./Layout/LeftSidebar";
import Login from "./Pages/Auth/Login";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
// import ClientManagement from "./Pages/";
import RequestManagement from "./Pages/RequestManagement";
import ManageSubAdmins from "./Pages/ManageSubAdmins";
import AddAdmin from "./Pages/ManageSubAdmins/AddAdmin";
import ViewAdmin from "./Pages/ManageSubAdmins/ViewAdmin";
import EditAdmin from "./Pages/ManageSubAdmins/EditAdmin";
import RolesAndPermissions from "./Pages/RolesAndPermissions";
import ViewRoles from "./Pages/RolesAndPermissions/ViewRoles";
import CMSPage from "./Pages/CMS";
import CmsEditor from "./Pages/CMS/CmsEditor";
import ViewRequestManagement from "./Pages/RequestManagement/ViewRequestManagement";
import RaiseRequest from "./Pages/RequestManagement/RaiseRequest";
import AccountSettings from "./Pages/AccountSettings";
import NotificationPage from "./Pages/NotificationPage";
import SendNotification from "./Pages/NotificationPage/SendNotification";
import ViewNotification from "./Pages/NotificationPage/ViewNotification";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import ViewClientManagement from "./Pages/ClientManagement/ViewClientManagement";
import ClientManagement from "./Pages/ClientManagement";
import AuthGuard from "./ProctedRoutes/AuthGuard";
import LoginGuard from "./ProctedRoutes/LoginGuard";
import BarLoader from "react-spinners/BarLoader";
import { currentLoader } from "./Redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import LiveChat from "./Pages/LiveChat";
import Pick from "./Pages/LiveChat/LiveChatTabs/Pick";
import ViewChatLog from "./Pages/LiveChat/LiveChatTabs/ViewChatLog";
import { getSocket, initializeSocket } from "./Socket/socket";
import InsuranceAgentView from "./Pages/InsuranceAgentView";
import ViewAgent from "./Pages/InsuranceAgentView/ViewAgent";
import PermissionRoute from "./ProctedRoutes/PermissonRoute";
import PageNotFound from "./Pages/PageNotFound";
import ResetPasswordGuard from "./ProctedRoutes/ResetPasswordGuard";
import {
  checkPermission,
  getOptimizedPermissons,
  refreshToken,
} from "./Helpers/Functions";
import addDeleteGetLocalStorage from "./Axios/addDeleteGetLocalStorage";
import { storageKeys } from "./Axios/Enum";
import { jwtDecode } from "jwt-decode";
import { changePermissonChange } from "./Redux/reducers/permissionChange";
import { changeTrigger } from "./Redux/reducers/trigger";
import CmsStatic from "./Pages/CMS/CmsStatic";
import DeleteAccount from "./Pages/DeleteAccount";
import ProviderCategories from "./Pages/ProviderCategories";
import AddCategories from "./Pages/ProviderCategories/AddCategories";

const { Content } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { i18n } = useTranslation();
  const loader = useSelector(currentLoader);
  const [socketConnected, setSocketConnected] = useState(false);
  const permissionChnage = useSelector((state) => state.permissionChange.value);
  const trigger = useSelector((state) => state.trigger.value);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const dispatch = useDispatch();
  let token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  token = token && jwtDecode(token);
  const toggleSidebar = (collapsedState) => {
    setCollapsed(collapsedState);
  };

  const changeLanguage = (lang) => {
    localStorage.setItem("i18nextLng", lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    let socket = getSocket();
    const readCount = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket?.on(token?.role, (data) => {
        if (data) {
          let permission = getOptimizedPermissons(data?.permission);
          addDeleteGetLocalStorage(
            storageKeys.ADMIN_PERMISSIONS,
            permission,
            "add",
            "single"
          );
          dispatch(changePermissonChange(!permissionChnage));
        }
      });
    };

    readCount();
    if (socket) {
      return () => {
        socket?.off(token?.role);
      };
    }
  }, [token?.role && permissionChnage]);

  useEffect(() => {
    let sockethandel = async () => {
      let socket = getSocket();
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      if (socket) {
        socket.on("connect", () => {
          console.log("connected");
        });
      }
    };
    sockethandel();
  }, []);

  // toast message events
  useEffect(() => {
    let socket = getSocket();
    const readCount = async () => {
      if (!socket?.connected) {
        await initializeSocket();
      }
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      if (checkPermission("manage_request", "view")) {
        socket?.on("new-request-toast", (data) => {
          if (data) {
            toast.success("New Request", {
              limit: 1,
              toastId: "forgotPasswordSuccess",
            });
          }
        });
      }
      if (checkPermission("live_chat", "view-chat-queue")) {
        socket?.on("new-chat-room-toast", (data) => {
          if (data?.chat_room?.id) {
            toast.success("New chat queue", {
              limit: 1,
              toastId: "forgotPasswordSuccess",
            });
          }
        });
      }
      if (checkPermission("message_sales", "view-message")) {
        socket.on(`agent-chat-read-count-toast-${token?.user_id}`, (data) => {
          if (data) {
            console.log(data);
            if (id != data?.chat_message?.room_id) {
              toast.success(
                `New insurance chat message '${data?.chat_message?.message}'`
                //    {
                //   limit: 3,
                //   toastId: "forgotPasswordSuccess",
                // }
              );
            }
          }
        });
      }
    };
    readCount();

    if (socket) {
      return () => {
        socket?.off("new-request-toast");
        socket?.off("new-chat-room-toast");
        socket?.off(`agent-chat-read-count-toast-${token?.user_id}`);
      };
    }
  }, [permissionChnage, socketConnected, id]);

  useEffect(() => {
    setTimeout(() => {
      setSocketConnected(!socketConnected);
    }, 3000);
  }, []);

  // useEffect(() => {
  //   let arr = ["chatRoomCLosed"];
  //   if (arr.includes(trigger)) {
  //     dispatch(changeTrigger(""));
  //   }
  // }, [trigger]);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      if (!["en"].includes(localStorage.getItem("i18nextLng"))) {
        localStorage.setItem("i18nextLng", "en");
        changeLanguage("en");
      }
      changeLanguage(localStorage.getItem("i18nextLng"));
    } else {
      localStorage.setItem("i18nextLng", "en");
      changeLanguage("en");
    }
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#0DBFF1",
        },
      }}
    >
      <Router>
        <Routes>
          {/* auth Route */}

          <Route element={<LoginGuard />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/page/:slug" element={<CmsStatic />} />
          <Route path="/page-not-found" element={<PageNotFound />} />
          <Route element={<ResetPasswordGuard />}>
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<AuthGuard />}>
            {/* Layout with Sidebar and Header */}
            <Route
              path="/*"
              element={
                <Layout style={{ minHeight: "100vh" }}>
                  <LeftSidebar
                    collapsed={collapsed}
                    toggleCollapsed={toggleSidebar}
                  />
                  <Layout>
                    <HeaderComponent toggleCollapsed={toggleSidebar} />
                    <Content style={{ margin: "16px 16px" }}>
                      <Routes>
                        {/* Dashboard Route */}

                        <Route
                          path="/client-management"
                          element={
                            <PermissionRoute roles={["manage_client"]}>
                              <ClientManagement />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/view-client-management/:slug"
                          element={
                            <PermissionRoute roles={["manage_client"]}>
                              <ViewClientManagement />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/request-management"
                          element={
                            <PermissionRoute roles={["manage_request"]}>
                              <RequestManagement />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/view-request/:slug"
                          element={
                            <PermissionRoute roles={["manage_request"]}>
                              <ViewRequestManagement />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/raise-request"
                          element={
                            <PermissionRoute
                              roles={["manage_request,raise-request"]}
                            >
                              <RaiseRequest />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/raise-request-client"
                          element={
                            <PermissionRoute
                              roles={["manage_request,raise-request"]}
                            >
                              <RaiseRequest />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/raise-request/:slug"
                          element={
                            <PermissionRoute
                              roles={["manage_request,raise-request"]}
                            >
                              <RaiseRequest />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/live-chat"
                          element={
                            <PermissionRoute roles={["live_chat"]}>
                              <LiveChat />
                            </PermissionRoute>
                          }
                        />
                         <Route
                          path="/provider-categories"
                          element={
                            <PermissionRoute roles={["category"]}>
                              <ProviderCategories />
                            </PermissionRoute>
                          }
                        />
                         <Route
                          path="/add-categories"
                          element={
                            <PermissionRoute roles={["category"]}>
                              <AddCategories />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/manage-sub-admin"
                          element={
                            <PermissionRoute roles={["sub_admin"]}>
                              <ManageSubAdmins />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/add-admin"
                          element={
                            <PermissionRoute roles={["sub_admin"]}>
                              <AddAdmin />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/view-admin/:slug"
                          element={
                            <PermissionRoute roles={["sub_admin"]}>
                              <ViewAdmin />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/edit-admin/:slug"
                          element={
                            <PermissionRoute roles={["sub_admin"]}>
                              <EditAdmin />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/roles-and-permissions"
                          element={
                            <PermissionRoute roles={["role_permission"]}>
                              <RolesAndPermissions />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/view-roles/:slug"
                          element={
                            <PermissionRoute roles={["role_permission"]}>
                              <ViewRoles />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/cms"
                          element={
                            token?.role == "super_admin" ? (
                              <CMSPage />
                            ) : (
                              <Navigate to="/page-not-found" />
                            )
                          }
                        />
                        <Route
                          path="/cms-editor/:slug"
                          element={
                            token?.role == "super_admin" ? (
                              <CmsEditor />
                            ) : (
                              <Navigate to="/page-not-found" />
                            )
                          }
                        />
                        <Route
                          path="/notification"
                          element={<NotificationPage />}
                        />
                        <Route
                          path="/send-notification"
                          element={<SendNotification />}
                        />
                        <Route 
                          path="/view-notification/:slug"
                          element={<ViewNotification />}
                        />
                        <Route
                          path="/account-settings"
                          element={<AccountSettings />}
                        />
                        <Route
                          path="/pink/:slug"
                          element={
                            <PermissionRoute roles={["live_chat"]}>
                              <Pick />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/view-chat-log/:slug"
                          element={
                            <PermissionRoute roles={["live_chat"]}>
                              <ViewChatLog />{" "}
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/insurance-agent-view"
                          element={
                            <PermissionRoute roles={["message_sales"]}>
                              <InsuranceAgentView />
                            </PermissionRoute>
                          }
                        />
                        <Route
                          path="/view-agent"
                          element={
                            <PermissionRoute roles={["message_sales"]}>
                              <ViewAgent />
                            </PermissionRoute>
                          }
                        />
                      </Routes>
                    </Content>
                  </Layout>
                </Layout>
              }
            />
          </Route>
        </Routes>
      </Router>
      {loader && (
        <div className="loader">
          <BarLoader
            color={"#0DBFF1"}
            height={10}
            width={300}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <ToastContainer />
    </ConfigProvider>
  );
};

export default App;
