import { Button, Card, Typography, Divider, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import RequestQueue from "./RequestManagementTabs/RequestQueue";
import PickedRequests from "./RequestManagementTabs/PickedRequests";
import { checkPermission } from "../../Helpers/Functions";
import { useEffect, useState } from "react";
import { GetAllRequest } from "../../Axios/services/ManageRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
const RequestManagement = () => {
  const navigate = useNavigate();
  const { TabPane } = Tabs;

  let [pageCount, setPageCount] = useState(0);
  let [page, setPage] = useState(1);
  let [limit, setLimit] = useState(20);
  const [sorting, setSorting] = useState({
    order: "DESC",
    sort_by: "id",
  });
  const [itemTotal, setItemTotal] = useState(0);
  const [responceData, setResponceData] = useState([]);
  const dispatch = useDispatch();
  const handlegetRequest = async () => {
    dispatch(changeLoader(true));
    try {
      let params = { page: page, limit: limit, is_queue: 1, ...sorting };
      const responce = await GetAllRequest(params);
      if (responce?.ack) {
        setResponceData(responce?.data);
        setItemTotal(responce?.data?.count);
        setPageCount(Math.ceil(responce?.data?.count / limit));
      }
      dispatch(changeLoader(false));
    } catch (err) {
      dispatch(changeLoader(false));
      console.log(err);
    }
  };

  const handleSort = (order, sortby) => {
    let obj = {
      order: order,
      sort_by: sortby,
    };
    setSorting(obj);
  };

  const handlePageChange = (newPage) => {
    page = newPage;
    setPage(newPage);
    handlegetRequest();
  };

  useEffect(() => {
    handlegetRequest();
  }, [sorting]);

  return (
    <>
      <Card>
        <div className="flex items-center justify-between flex-wrap relative gap-3 mb-4">
          <Typography className="text-xl font-semibold mb-0" align={"left"}>
            Support Requests
          </Typography>
          <div className="flex justify-end flex-wrap gap-3">
            <div className="text-[16px] font-semibold flex items-center">
              {/* Total Requests - {itemTotal} */}
            </div>
            {checkPermission("manage_request", "raise-request") && (
              <Button
                type="primary"
                onClick={() => navigate("/raise-request")}
                icon={<PlusOutlined />}
              >
                Raise new Request
              </Button>
            )}
          </div>
        </div>
        <Divider />
        <div className="relative flex flex-col gap-4">
          <Tabs defaultActiveKey="1">
            {" "}
            <TabPane tab="Request queue" key="1">
              <RequestQueue
                responceData={responceData?.rows}
                handlePageChange={handlePageChange}
                pageCount={pageCount}
                page={page}
                limit={limit}
                handlegetRequest={handlegetRequest}
                itemTotal={itemTotal}
                handleSort={handleSort}
              />
            </TabPane>{" "}
            <TabPane tab="Picked requests" key="2">
              <PickedRequests />
            </TabPane>{" "}
          </Tabs>
        </div>
      </Card>
    </>
  );
};

export default RequestManagement;
