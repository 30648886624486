import axiosInstance from "../AxiosInstance"

export const getNotifications = (params) => {
    return axiosInstance.get(`/admin/notification/getAllNotifications` , {params})
}

export const readNotification = (id) => {
    return axiosInstance.patch(`/admin/notification/readNotification/${id}`)
}

export const getUnreadNotificationCount = () => {
    return axiosInstance.get(`/admin/notification/getUnreadNotifications`)
}

export const getreadAllNotification = () => {
    return axiosInstance.get(`/admin/notification/readAllNotifications`)
}

export const geNotificationList = (params) => {
    return axiosInstance.get(`/admin/notification/custom-notification-list`, { params })
}

export const sendNotification = (data) => {
    return axiosInstance.post(`/admin/notification/send-custom-notification`, data)
}

export const getNotificationDetails = (id) => {
    return axiosInstance.get(`/admin/notification/custom-notification/${id}`)
}

export const customNotificationFilterEntity = () => {
    return axiosInstance.get(`/admin/notification/custom-notification-filter-entity`);
}







