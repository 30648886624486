import React, { useEffect } from "react";
import { Button, Card, Divider, Form, Input, Typography, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
import {
  customNotificationFilterEntity,
  sendNotification,
} from "../../Axios/services/Notification";
import { toast } from "react-toastify";

// Dummy master data for testing purposes
const selectUserType = [
  { value: "1", label: "Select User Type" },
  { value: "2", label: "All Clients" },
  { value: "3", label: "Custom" },
];

const selectFieldType = [
  { value: "1", label: "Select Field" },
  { value: "specific_carrier", label: "Specific Carrier" },
  { value: "state", label: "State" },
  { value: "city", label: "City" },
  { value: "zip_code", label: "Zipcode" },
  { value: "age", label: "Age" },
];

const SendNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customNotificationEntity, setCustomNotificationEntity] =
    React.useState({});
  const [userType, setUserType] = React.useState("1");
  const [feild, setFeild] = React.useState("1");
  const [value, setvalue] = React.useState("");
  const [FeildValueArray, setFeildValueArray] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");
  //Error
  const [userTypeError, setUserTypeError] = React.useState("");
  const [feildError, setFeildError] = React.useState("");
  const [valueError, setValueError] = React.useState("");
  const [titleError, setTitleError] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [form] = Form.useForm();
  // handle user type change
  const handleUserTypeChange = (value) => {
    setUserType(value);
    setUserTypeError("");
  };
  //handle value change
  const handleValueChange = (selectedValue) => {
    setvalue(selectedValue);
    setValueError("");
  };
  // Handle field change
  const handleFeildChange = (value) => {
    // Clear previous value first
    setvalue("");
    setFeild(value);
    setFeildError("");
    if (value === "state") {
      const mappedStates = customNotificationEntity?.stateData?.map((item) => ({
        value: item.state,
        label: item.state,
      }));
      setFeildValueArray(mappedStates);
    }
    if (value === "city") {
      const mappedCities = customNotificationEntity?.cityData?.map((item) => ({
        value: item.city,
        label: item.city,
      }));
      setFeildValueArray(mappedCities);
    }
    if (value === "specific_carrier") {
      console.log(customNotificationEntity, "customNotificationEntity");
      const mappedZipcodes = customNotificationEntity?.policyIssuerData?.map(
        (item) => ({
          value: item.policy_issuer,
          label: item.policy_issuer,
        })
      );
      setFeildValueArray(mappedZipcodes);
    }
  };
  //handle error
  const handleError = () => {
    let error = false;
    if (!title) {
      setTitleError("Please enter message title");
      error = true;
    }
    if (title && title.length > 50) {
      setTitleError("Maximum 50 characters allowed");
      error = true;
    }
    if (!message) {
      setMessageError("Message is required");
      error = true;
    }
    if (message && message.length > 250) {
      setMessageError("M<aximum 250 characters allowed");
      error = true;
    }
    if (userType && userType === "1") {
      setUserTypeError("Please select user type");
      error = true;
    }
    if (userType === "3" && feild && feild === "1") {
      setFeildError("Please select field");
      error = true;
    }
    if (!value && userType === "3") {
      setValueError("Please select value");
      error = true;
    }
    return error;
  };

  //hanndle send notification
  const handleSendNotification = async () => {
    if (!handleError()) {
      dispatch(changeLoader(true));
      try {
        const response = await sendNotification({
          type: userType === "2" ? "all" : "custom",
          field: userType === "2" ? "" : feild === "1" ? "" : feild,
          value: userType === "2" ? "" : value,
          title: title,
          message: message,
        });
        if (response.ack) {
          toast.success("Notification sent successfully", {
            limit: 1,
            toastId: "notificationSuccess",
          });
          navigate("/notification");
        } else {
          console.log(feild, "jkhjk");
          toast.error(
            `No user found on this ${
              feild === "zip_code"
                ? "zipcode"
                : feild === "age"
                ? "age"
                : feild === "specific_carrier"
                ? "specific carrier"
                : feild === "state"
                ? "state"
                : feild
            }!`,
            {
              limit: 1,
              toastId: "notificationError",
            }
          );
        }
        dispatch(changeLoader(false));
      } catch (err) {
        console.log(err);
        dispatch(changeLoader(false));
      }
    }
  };
  // Fetch custom notification entities for dropdown options
  const getCustomNotificationEntity = async () => {
    try {
      const response = await customNotificationFilterEntity();
      if (response?.ack) {
        setCustomNotificationEntity(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCustomNotificationEntity();
  }, []);

  return (
    <Card>
      <Typography className="text-xl font-semibold" align={"left"}>
        <LeftOutlined className="mr-2" onClick={() => navigate(-1)} />
        Send Notification
      </Typography>
      <Divider />
      <div className="min-h-[calc(100vh_-_305px)]">
        <Form
          form={form}
          name="Send Notification"
          layout="vertical"
          style={{
            maxWidth: 500,
            width: "100%",
          }}
          autoComplete="off"
        >
          <Form.Item
            label={
              <>
                User type
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="UserType"
          >
            <Select
              size="large"
              options={selectUserType}
              value={userType}
              onChange={handleUserTypeChange}
            />
            <span className="text-red-500">{userTypeError}</span>
          </Form.Item>
          {(userType === "1" || userType === "3") && (
            <>
              <Form.Item
                label={
                  <>
                    Field
                    <span style={{ color: "red" }}> *</span>
                  </>
                }
                name="Field"
                onMetaChange={handleFeildChange}
              >
                <Select
                  size="large"
                  options={selectFieldType}
                  value={feild}
                  onChange={handleFeildChange}
                />
                <span className="text-red-500">{feildError}</span>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    Value
                    <span style={{ color: "red" }}> *</span>
                  </>
                }
                name="Value"
              >
                {feild === "age" || feild === "zip_code" ? (
                  <Input
                    size="large"
                    type="number"
                    min="0"
                    value={value}
                    onChange={(e) => {
                      const numericValue = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      );
                      setvalue(numericValue.toString());
                      setValueError("");
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                ) : (
                  <Select
                    size="large"
                    options={FeildValueArray}
                    value={value}
                    onChange={handleValueChange}
                  />
                )}
                <span className="text-red-500">{valueError}</span>
              </Form.Item>
            </>
          )}
          <Form.Item
            label={
              <>
                Title
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="Title"
          >
            <Input
              size="large"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError("");
              }}
            />
            <span className="text-red-500">{titleError}</span>
          </Form.Item>
          <Form.Item
            label={
              <>
                Message
                <span style={{ color: "red" }}> *</span>
              </>
            }
            name="Message"
          >
            <TextArea
              showCount
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setMessageError("");
              }}
              placeholder="Enter Your Message"
              rows={3}
              maxLength={250}
              className="w-full mb-4"
            />
            <span className="text-red-500">{messageError}</span>
          </Form.Item>
        </Form>
      </div>
      <Divider />
      <div className="flex justify-end gap-3">
        <Button onClick={() => navigate(-1)}>Cancel</Button>
        <Button
          type="primary"
          disabled={
            userType === "1" ||
            (userType === "3" && (feild === "1" || value === "")) ||
            title === "" ||
            message === ""
          }
          onClick={handleSendNotification}
        >
          Send
        </Button>
      </div>
    </Card>
  );
};

export default SendNotification;
