import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Drawer } from "antd";
import {
  getNotifications,
  getreadAllNotification,
  readNotification,
} from "../../Axios/services/Notification";
import DateHelper from "../../Helpers/lib/DateHelper";
import EmptyComponent from "../../Pages/Empty";
import BarLoader from "react-spinners/BarLoader";
import useIntersection from "../../Helpers/Functions/useIntersection";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BellOutlined } from "@ant-design/icons";

const Notification = ({ isOpen, onClose }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [temp, setTemp] = useState(false);
  const inViewport = useIntersection(ref, "500px", temp);
  const [notifications, setNotifications] = useState([]);
  const [canLoad, setCanLoad] = useState(true);
  const [page, setPage] = useState(1);
  const limit = 10;
  const dispatch = useDispatch();
  const handleGetNotifications = async (type = "") => {
    try {
      let params = { page: page, limit: limit };
      let response = await getNotifications(params);
      if (response.ack) {
        console.log(response);
        if (response?.data?.rows?.length < limit) {
          setCanLoad(false);
        }
        if (type === "readAllNotification") {
          setNotifications(response?.data?.rows);
        } else {
          setNotifications((prevNotifications) => {
            return page > 1
              ? [...prevNotifications, ...response?.data?.rows]
              : response?.data?.rows;
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReadAllNotification = async () => {
    dispatch(changeLoader(true));
    try {
      let response = await getreadAllNotification();
      if (response?.ack) {
        handleGetNotifications("readAllNotification");
        setPage(1);
        // onClose();
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(changeLoader(false));
  };

  const handleReadNotification = async (id) => {
    dispatch(changeLoader(true));
    try {
      let response = await readNotification(id);
      if (response.ack) {
        handleGetNotifications();
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    setTimeout(() => {
      setTemp(true);
    }, 100);

    if (temp) {
      handleGetNotifications();
    }
  }, []);

  useEffect(() => {
    if (inViewport && canLoad) {
      setPage((prevPage) => prevPage + 1);
      handleGetNotifications();
    }
  }, [inViewport, canLoad]);

  return (
    <Drawer
      title={
        <div className="flex items-center justify-between">
          <span>Notifications</span>
          <Button
            type="primary"
            onClick={handleReadAllNotification}
            disabled={!notifications?.map((item) => item?.is_read).includes(0)}
          >
            Read All
          </Button>
        </div>
      }
      className="notification-drawer"
      onClose={onClose}
      open={isOpen}
    >
      <div className="flex flex-col gap-3">
        <div className="w-full h-full overflow-auto">
          {notifications.length > 0 ? (
            notifications.map((item) => (
              <div key={item?.id}>
                <div
                  className="flex gap-3.5 items-start bg-white py-4 px-[24px] hover:bg-gray-100 cursor-pointer relative"
                  onClick={() => {
                    handleReadNotification(item?.id);
                    if (item?.type === "request") {
                      navigate(`/view-request/${item?.item_id}`);
                    }
                    if (item?.type === "authorization") {
                      navigate(`/view-client-management/${item?.item_id}`);
                    }
                    if(item?.type === "chat"){
                      navigate(`/live-chat`);
                    }
                    onClose();
                  }}
                >
                  <div className="">
                    <BellOutlined />
                  </div>
                  <div className="flex flex-col gap-2 relative">
                    {/* Red Dot */}
                    {item?.is_read == 0 && (
                      <div
                        className="absolute top-0 -left-4 w-2 h-2 rounded-full"
                        style={{ backgroundColor: "#0DBFF1" }}
                      ></div>
                    )}

                    <div className="text-sm font-normal leading-[16.66px]">
                      {item?.notification}
                    </div>
                    <div className="text-xs font-normal leading-[14.28px] text-[#b2b2b2]">
                      {DateHelper.format(item?.createdAt, "d/m/Y h:i A")}
                    </div>
                  </div>
                </div>
                <Divider className="m-0" />
              </div>
            ))
          ) : (
            <EmptyComponent description="No Notifications" />
          )}
        </div>
        {canLoad && (
          <div ref={ref}>
            <BarLoader
              color={"#0DBFF1"}
              height={10}
              width={300}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default Notification;
