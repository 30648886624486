import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import noDigital from "../../../images/no-digital-card.jpg";
import { useState } from "react";
import { changeLoader } from "../../../Redux/reducers/loader";
import { useDispatch } from "react-redux";
import { Option } from "antd/es/mentions";
import { updateClientPolicyDepedentDetails } from "../../../Axios/services/ClientManagement";
import { toast } from "react-toastify";
import { checkPermission } from "../../../Helpers/Functions";
const { Panel } = Collapse;

const Dependent = ({ policy, getClientDetail }) => {
  const dispatch = useDispatch();
  const [isEditPolicyDepedent, setIsEditPolicyDepedent] = useState(false);
  const [formData, setFormData] = useState({
    depedentName: "",
    gender: "",
    relationship: "",
  });
  const [formDataError, setFormDataError] = useState({
    nameError: "",
    genderError: "",
    relationshipError: "",
  });
  const handleUpdatePolicy = () => {
    setIsEditPolicyDepedent(true);
    setFormData({
      depedentName: policy?.dependents[0]?.dependent_full_name,
      gender: policy?.dependents[0]?.dependent_gender,
      relationship: policy?.dependents[0]?.dependent_type,
    });
  };
  // Handle error
  const handleError = () => {
    let error = false;
    if (!formData.depedentName) {
      setFormDataError((prev) => ({
        ...prev,
        nameError: "Please enter dependent name",
      }));
      error = true;
    }
    if (!formData.gender) {
      setFormDataError((prev) => ({
        ...prev,
        genderError: "Please enter gender",
      }));
      error = true;
    }
    if (!formData.relationship) {
      setFormDataError((prev) => ({
        ...prev,
        relationshipError: "Please enter relationship",
      }));
      error = true;
    }
    return error;
  };

  const handleSubmit = async (id) => {
    if (!handleError()) {
      dispatch(changeLoader(true));
      try {
        let params = {
          dependent_full_name: formData?.depedentName,
          dependent_gender: formData?.gender,
          dependent_type: formData?.relationship,
        };
        let responce = await updateClientPolicyDepedentDetails(id, params);
        if (responce.ack) {
          setIsEditPolicyDepedent(false);
          toast[responce.ack ? "success" : "error"](responce.message, {
            limit: 1,
          });
        }
        getClientDetail();
        dispatch(changeLoader(false));
      } catch (err) {
        console.log(err);
        dispatch(changeLoader(false));
      }
    }
  };

  const handleCancel = () => {
    setIsEditPolicyDepedent(false);
    setFormDataError({
      nameError: "",
      genderError: "",
      relationshipError: "",
    });
  };

  return (
    <>
      <Collapse accordion bordered={false}>
        {policy?.dependents &&
          policy?.dependents.map((dependent, index) => (
            <Panel
              header={`Dependent ${index + 1} - ${dependent?.dependent_full_name
                }`}
              key={index}
            >
              <Card>
                <div className="flex justify-between items-center mb-4 gap-3">
                  <Typography className="text-xl font-semibold" align={"left"}>
                    Policy Detail
                  </Typography>
                  {!isEditPolicyDepedent && (
                    <div className="flex justify-end">
                      {checkPermission("manage_client", "update_client_policy") && (
                        <Button
                          type="primary"
                          onClick={() => {
                            handleUpdatePolicy();
                          }}
                        >
                          Edit Dependent
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <Divider />
                <div className="w-full">
                  <img
                    src={
                      dependent?.policy_card_doc
                        ? dependent?.policy_card_doc
                        : noDigital
                    }
                    alt="no-digital-card"
                    className="w-[200px] h-[120px] mb-[14px]"
                  />
                  <div className="flex mb-8">
                    <Row gutter={[40, 16]}>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Unique ID :{" "}
                        </div>
                        <div className="text-base">{dependent?.policy_id}</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Gender :{" "}
                        </div>
                        {isEditPolicyDepedent ? (
                          <Select
                            size="large"
                            value={formData?.gender || undefined}
                            defaultValue={
                              policy?.dependents[0]?.dependent_gender
                            } // Add default value
                            onChange={(value) => {
                              setFormData({
                                ...formData,
                                gender: value,
                              });
                              setFormDataError((prev) => ({
                                ...prev,
                                genderError: "",
                              }));
                            }}
                            style={{ width: "100%" }}
                          >
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                          </Select>
                        ) : (
                          <div className="text-base capitalize">
                            {dependent?.dependent_gender}
                          </div>
                        )}
                        <span className="text-red-500">
                          {formDataError.genderError}
                        </span>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Dependent Name :{" "}
                        </div>
                        {isEditPolicyDepedent ? (
                          <Input
                            size="large"
                            type="text"
                            value={formData?.depedentName}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                depedentName: e.target.value,
                              });
                              setFormDataError((prev) => ({
                                ...prev,
                                depedentNameError: "",
                              }));
                            }}
                          />
                        ) : (
                          <div className="text-base">
                            {dependent?.dependent_full_name}
                          </div>
                        )}
                        <span className="text-red-500">
                          {formDataError.depedentNameError}
                        </span>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Relationship to policyholder :{" "}
                        </div>

                        {isEditPolicyDepedent ? (
                          <Select
                            size="large"
                            value={formData?.relationship || undefined}
                            defaultValue={policy?.dependents[0]?.dependent_type}
                            onChange={(value) => {
                              setFormData({
                                ...formData,
                                relationship: value,
                              });
                              setFormDataError((prev) => ({
                                ...prev,
                                relationshipError: "",
                              }));
                            }}
                            style={{ width: "100%" }}
                          >
                            <Option value="spouse">Spouse</Option>
                            <Option value="child">Child</Option>
                            <Option value="other_1">Other</Option>
                          </Select>
                        ) : (
                          <div className="text-base">
                            {dependent?.dependent_type === "spouse"
                              ? "Spouse"
                              : dependent?.dependent_type === "child" ? "Child" : "Other"}
                          </div>
                        )}
                        <span className="text-red-500">
                          {formDataError.relationshipError}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="flex justify-center gap-3 mt-5">
                    {isEditPolicyDepedent && (
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                          type="primary"
                          onClick={() => {
                            handleSubmit(policy?.dependents[0]?.id);
                          }}
                          disabled={!formData.depedentName}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </Panel>
          ))}
      </Collapse>
    </>
  );
};

export default Dependent;
